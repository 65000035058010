import styled from "styled-components";

export const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  margin: 50px 0;

  @media screen and (min-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  @media screen and (min-width: 769px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }
`;

export const CardItem = styled.div`
  /*border: 1px solid #eee;*/
  padding: 10px 20px;
  text-align: left;

  @media screen and (min-width: 480px) {
    padding: 16px;
  }
`;

export const CardImageWrapper = styled.div`
  margin-bottom: 10px;

  img {
    border-radius: 3px;
  }

  @media screen and (min-width: 768px) {
    display: inline-block;
  }
`;

export const TextWrapper = styled.div`
  max-width: 800px;

  h4 {
    margin: 0;
  }

  a {
    text-decoration: none;
  }
  p {
    font-size: 0.9rem;
  }
`;
