import styled from "styled-components";

export const Quote = styled.blockquote`
  font-size: 12px;
  color: white;
  border-left: 3px solid white;
  padding-left: 6px;
  text-align: left;

  @media screen and (min-width: 769px) {
    font-size: 22px;
    padding-left: 12px;
  }

  p {
    font-size: 10px;

    @media screen and (min-width: 769px) {
      font-size: 16px;
    }
  }
`;
