import React from "react";
import { Link } from "gatsby";
import { LeftSideWrapper, RightSideWrapper } from "../../styles/ColumnWrappers";
import { RichText } from "prismic-reactjs";
import CardGrid from "../CardGrid";
import { Button } from "../../styles/Button";
import { Quote } from "../../styles/Quote";
import { PopupText } from "react-calendly";

const SliceZone = ({ body }) => {
  return (
    <div>
      {body.map((slice, index) => {
        switch (slice.type) {
          case "quote":
            return (
              <section key="quote" className="quote-section">
                <LeftSideWrapper>
                  <div style={{ background: "white" }}>
                    <img
                      src={slice.primary.portrait_author.custom.url}
                      alt={slice.primary.portrait_author.custom.alt}
                    />
                  </div>
                  <div>
                    <Quote>
                      {slice.primary.quote[0].text}
                      <p>
                        <em>-{slice.primary.name_of_the_author[0].text}</em>
                      </p>
                    </Quote>
                  </div>
                </LeftSideWrapper>
              </section>
            );

          case "cards":
            return (
              <section id={slice.primary.section} key={slice.primary.section}>
                <RichText render={slice.primary.title} />
                <CardGrid cards={slice.fields} />
                <Link to={`/robot`}>
                  <Button className="hire-robot-btn">
                    Kuinka palkkaan robotin?
                  </Button>
                </Link>
              </section>
            );

          case "image_text":
            if (slice.primary.type === "Leftside_Image") {
              return (
                <section
                  id={slice.primary.section}
                  key={slice.primary.section}
                  style={{
                    background: slice.primary.background_color,
                    color: slice.primary.color,
                  }}
                >
                  <LeftSideWrapper>
                    <div>
                      <img
                        src={slice.primary.slice_image.url}
                        alt={slice.primary.slice_image.alt}
                      />
                    </div>
                    <div>
                      <RichText render={slice.primary.text} />
                      {slice.primary.section === "start" && (
                        <a href="#contact">
                          <Button>Varaa esittely</Button>
                        </a>
                      )}
                    </div>
                  </LeftSideWrapper>
                </section>
              );
            } else if (slice.primary.type === "Rightside_Image") {
              return (
                <section
                  id={slice.primary.section}
                  key={slice.primary.section}
                  style={{
                    background: slice.primary.background_color,
                  }}
                >
                  {slice.primary.title && (
                    <RichText render={slice.primary.title} />
                  )}
                  <RightSideWrapper>
                    <div>
                      <RichText render={slice.primary.text} />
                    </div>
                    <div>
                      <img
                        src={slice.primary.slice_image.url}
                        alt={slice.primary.slice_image.alt}
                      />
                    </div>
                  </RightSideWrapper>
                </section>
              );
            } else if (slice.primary.type === "Calendar_Image") {
              return (
                <section
                  id={slice.primary.section}
                  key={slice.primary.section}
                  style={{
                    background: slice.primary.background_color,
                    color: "#fff",
                  }}
                >
                  <LeftSideWrapper>
                    <img
                      src={slice.primary.slice_image.url}
                      alt={slice.primary.slice_image.alt}
                    />

                    <div id="calendar">
                      <RichText render={slice.primary.text} />
                      <PopupText
                        text="Varaa aika esittelylle"
                        url="https://calendly.com/timo-sahakari"
                      />
                    </div>
                  </LeftSideWrapper>
                </section>
              );
            } else return null;

          default:
            return null;
        }
      })}
    </div>
  );
};

export default SliceZone;
