import React from "react";
import {
  CardWrapper,
  CardItem,
  CardImageWrapper,
  TextWrapper,
} from "./CardGrid.styles";
import { RichText } from "prismic-reactjs";

const CardGrid = ({ cards }) => {
  return (
    <CardWrapper>
      {cards.map((card, index) => {
        return (
          <CardItem key={index}>
            <CardImageWrapper>
              <img
                src={card.card_link.card_logo.url}
                alt={card.card_link._meta.uid}
              />
            </CardImageWrapper>
            <TextWrapper>
              <h4>{RichText.asText(card.card_link.card_title)}</h4>
              <RichText render={card.card_link.card_content} />
            </TextWrapper>
          </CardItem>
        );
      })}
    </CardWrapper>
  );
};

export default CardGrid;
