import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import SliceZone from "../components/SliceZone";

const HomePage = ({ data }) => {
  const body = data.prismic.allHomepages.edges[0].node.body;

  return (
    <Layout>
      <SEO title="Citrus Robotti" />
      <SliceZone body={body} />
    </Layout>
  );
};

export default HomePage;

export const homepageQuery = graphql`
  query HomePageQuery {
    prismic {
      allHomepages {
        edges {
          node {
            _meta {
              id
              lang
            }
            body {
              ... on PRISMIC_HomepageBodyQuote {
                type
                primary {
                  name_of_the_author
                  portrait_author
                  quote
                }
              }
              ... on PRISMIC_HomepageBodyImage_text {
                type
                primary {
                  type
                  section
                  background_color
                  color
                  title
                  text
                  slice_image
                  link_page {
                    ... on PRISMIC_Contactpage {
                      _meta {
                        uid
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_HomepageBodyCards {
                type
                primary {
                  section
                  title
                }
                fields {
                  card_link {
                    ... on PRISMIC_Card {
                      _meta {
                        uid
                      }
                      card_logo
                      card_title
                      card_content
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
