import styled from "styled-components";

export const Button = styled.button`
  cursor: pointer;
  padding: 10px 20px;
  font-size: 1.2em;
  background: ${props => props.theme.colors.textCustom};
  color: white;
  font-weight: 300;
  border-radius: 0.3em;
  border: none;

  span {
    margin-left: 10px;
  }

  &:hover {
    opacity: 0.9;
  }
  &:focus {
    outline: 0 !important;
    box-shadow: none !important;
  }
  &:active {
    transform: scale(0.98);
  }
`;
