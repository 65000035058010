import styled from "styled-components";

export const LeftSideWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
  text-align: center;

  div {
    padding: 20px;
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: auto auto;
    gap: 10px;
    text-align: left;
  }
`;

export const RightSideWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  text-align: center;

  div {
    padding: 20px;
  }

  img {
    justify-content: center;
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: auto minmax(300px, 350px);
    margin: 10px 0;
    gap: 10px;
    text-align: left;
  }
`;
